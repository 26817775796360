import { AdDetails } from '../../../@types/adCommon.js';

export type ReportingObserverOptions = {
    onReport: (error: InterventionReportError) => void;
};

export class InterventionReportError extends Error {}

export type InterventionReportAdDetails = Pick<
    AdDetails,
    'adCreativeMetaData' | 'creative' | 'slotName' | 'placementId' | 'placementName' | 'pageType'
>;

/**
 * When ReportingObserver detects a heavy ad intervention, send those reports when the ad is killed.
 * For more context, see: https://developer.chrome.com/blog/heavy-ad-interventions
 */
export const setupReportingObserver = (adDetails: AdDetails, options: ReportingObserverOptions) => {
    const { onReport = () => {} } = options;

    // @NOTE(calwlai): Clean this up when API is widely available:
    // TT: https://sim.amazon.com/issues/CPP-40204
    if (typeof window.ReportingObserver !== 'undefined') {
        const checkReports = (reports: Report[] = []) => {
            const interventionReportAdDetails = generateAdDetailsForReport(adDetails);

            reports.forEach((report: Report) => {
                if (report.type === 'intervention') {
                    const error = new InterventionReportError(
                        JSON.stringify({ adDetails: interventionReportAdDetails, report: report.toJSON() }),
                    );

                    onReport(error);
                }
            });
        };

        const reportingObserver = new ReportingObserver(checkReports, { buffered: true });

        // start observer
        reportingObserver.observe();

        // setup a visibility listener, as an intervention will begin to unload the iframe and reports may otherwise be
        // missed
        document.addEventListener('pagehide', () => {
            // pull all pending reports from the queue
            checkReports(reportingObserver.takeRecords());
        });
    }
};

export const generateAdDetailsForReport = (adDetails: AdDetails): InterventionReportAdDetails => {
    const { adCreativeMetaData, creative, slotName, placementId, placementName, pageType } = adDetails;

    return {
        adCreativeMetaData,
        creative,
        slotName,
        placementId,
        placementName,
        pageType,
    };
};
