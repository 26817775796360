import type { SFClientAPI, SFClientVideoAPI } from '@amzn/safe-frame-client-amzn';
import type { InternalSFClientAPI } from './InternalSFClientAPI.js';
import { CommonApiImplementation } from './CommonApiImplementation.js';
import type { DesktopSFClientAPI, PlacementInfo } from '@amzn/safe-frame-client-desktopsfclient';
import type { AdDetails } from '../../@types/adCommon.js';
import type { AdCreativeMetaData } from '../../@types/adCommon.js';
import { ClientMessageSender } from './clientMessageSender.js';
import { ClientMessageListeners } from './clientMessageReceiver.js';

// Defines sets of Desktop apis for use within the iframe and by creatives.
export class DesktopSFClientAPIImplementation
    extends CommonApiImplementation
    implements SFClientVideoAPI, SFClientAPI, InternalSFClientAPI, DesktopSFClientAPI
{
    constructor(
        cms: ClientMessageSender,
        o: AdDetails,
        mL: ClientMessageListeners,
        renderFallbackExperience: (forced?: boolean) => void,
    ) {
        super(cms, o, mL, renderFallbackExperience);
    }
    isSupported = () => {
        // TODO Remove in the public API
        return true;
    };
    getARID = () => {
        return this.o.arid;
    };
    sendAdInfo = (adInfo: AdCreativeMetaData) => {
        if (adInfo && this.o.adCreativeMetaData) {
            this.o.adCreativeMetaData = adInfo;
        }
    };
    getPlacementInfo = (): PlacementInfo => {
        this.logAPIInvocation('getPlacementInfo');
        return {
            slot: this.o.placementName,
            slotName: this.o.slotName,
            pageType: this.o.pageType,
        };
    };
}
